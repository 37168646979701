/* eslint-disable no-underscore-dangle */
import create from 'zustand';

const spacepublic2 = {
  category: '',
  spaceId: null,
  formules: null,
  formuleSelected: {},
  spaceDataByCate: {},
  datesSelected: {},
  riders: 0,
  cartridgeStatus: {},
  runResume: null,
  _initFromApp: '',
};

const useSpacePublicZustandStore = create(() => ({
  ...spacepublic2,
}));

/* export const SpacePublicProvider = (fnComponent, {
  categoryDefault,
  formulesDefault,
  spaceIdDefault,
  spaceRefDefault,
  spacePublicUrls
}) => {
  console.log('space public init data', categoryDefault, spaceIdDefault)
  useSpacePublicZustandStore.setState({
    category: categoryDefault,
    spaceId: spaceIdDefault,
    spaceRef: spaceRefDefault,
    cartridgeOpenDates: false,
    cartridgeOpenFormules: true,
    spacePublicUrls
  })

  return fnComponent()
}; */

export function initNoClientData(initData) {
  const { spaceRef, category } = initData;
  const keyInit = `${spaceRef}${category}`;
  const initFromApp = useSpacePublicZustandStore.getState()?._initFromApp;
  if (initFromApp === keyInit) return null;

  useSpacePublicZustandStore.setState({
    ...initData,
    _initFromApp: keyInit,
  });
  return null;
}

export default useSpacePublicZustandStore;
export {
  useSpacePublicZustandStore,
};
